import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image/withIEPolyfill'

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import WhyNav from '../../components/why-nav'
import Layout from '../../components/layout'

export default class TheScience extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const fourDotsImg = get(this, 'props.data.dots.childImageSharp.fluid')
    const studyImg = get(this, 'props.data.parade.childImageSharp.fluid')
    const thesisUrl =
      'https://assets.ctfassets.net/9b32znzhid1j/YWDI4prHRQglPqakLCslm/fe045b6a47f5128c0f402d1b110d7f5d/Masters_Thesis_Lynn_Kuske.pdf'

    // allow auto meta description
    return (
      <Layout pageTitle="The Science">
        <WhyNav path={this.props.path} />

        <Card>
          <a href={thesisUrl}>
            <Card.Img
              as={Img}
              fluid={studyImg}
              alt="4-group number parade 1 through 10"
              style={{ height: '125px', border: '1px solid rgba(0,0,0,0.1)' }}
            />
          </a>
          <Card.Body>
            <p className="lead">
              Number Patterns that Foster Number Sense in K-2 Student — A Brain
              Based Model
            </p>
            <Button variant="outline-primary mx-2" href={thesisUrl}>
              Read the Study
            </Button>
          </Card.Body>
        </Card>

        <hr />

        <h2>The Importance of Number Sense</h2>
        <p>
          Number sense forms the foundation for more advanced mathematics
          skills. Number sense is as important to mathematics learning as
          phonemic awareness (or phonics) is to reading.
        </p>
        <p className="lead">
          Children with good number sense are flexible in their thinking. They:
        </p>
        <ul>
          <li>
            move seamlessly between the real world of quantities and the
            mathematical world of numbers and numerical expressions
          </li>
          <li>invent their own procedures</li>
          <li>represent the same number in multiple ways</li>
          <li>recognize benchmark numbers</li>
          <li>recognize number patterns</li>
          <li>
            understand that numbers are representative of objects, magnitudes,
            relationships, and other attributes
          </li>
          <li>
            understand that numbers can be operated on, compared, and used for
            communication
          </li>
        </ul>
        <p className="lead">Children with good number sense excel at:</p>
        <ul>
          <li>mental calculation (Hope & Sherrill, 1987; Trafton, 1992)</li>
          <li>computational estimation (Bobis, 1991; Case & Sowder, 1990)</li>
          <li>judging the relative magnitude of numbers (Sowder, 1988)</li>
          <li>
            recognizing part-whole relationships and place value concepts
            (Fischer, 1990; Ross, 1989)
          </li>
          <li>problem solving (Cobb et.al., 1991)</li>
        </ul>

        <hr />

        <h2>Children's Ability to Subitize</h2>
        <Img
          fluid={fourDotsImg}
          alt="four dots in a 2x2 pattern are 'subitizable' while four dots in a 1x4 are not"
        />
        <p>
          The 4-group Number Patterns™ are based on a child’s innate ability to
          process simple patterns, independent of experience, prior knowledge,
          or language. This ability is called subitizing (pronounced
          sue-bĭ-tye-zing); generally defined as the rapid, accurate, and
          confident judgment of the quantity of a set of objects, without
          counting. Watch the video below to experience subitizing.
        </p>
        <div className="video-container">
          <video src="/media/subitizing.mp4" controls></video>
        </div>

        <hr />

        <h2>The 4-group Number Patterns™ 1-10</h2>
        <p>
          The 4-group Number Patterns™ are based on our Original 4-group Method™
          and were designed by Founder Lynn Kuske. The number patterns help
          develop children’s number sense by leveraging their innate ability to
          subitize four items in a square pattern without counting. The number
          patterns become second nature to children allowing them to accurately
          and confidently know how many are in a set of objects without
          counting! This builds a level of confidence in children right from the
          start. All of the 4-group Number Patterns™ fit together like a puzzle
          to form the pattern for their sum, making it easy to learn basic
          addition facts. Children are taught to recognize and subitize the
          patterns by seeing the four(s) in each pattern. These unique patterns
          are used on all visual models presented to children including our
          4-group Number Blocks, 4-group Dice, 4-group Playing Cards, 4-group
          Puzzles and a host of games and other activities. Using the 4-group
          Number Patterns is easy for children because the patterns work with
          how their brain works!
        </p>

        <hr />

        <h2>Why {siteTitle}?</h2>
        <p>
          Lynn Kuske recognized, during her 20 years in math education, that a
          child’s number sense foundation was critical to success in mathematics
          early and in later years. However, she could not find a number sense
          teaching method that reached all children. Through experience, Mrs.
          Kuske created the 4-group Number Patterns™ and began using them with
          children. Pre/post testing of children using the 4-group Number
          Patterns™, adapted to an existing curriculum, showed higher test
          scores over a control group. More important than test scores is the
          qualitative feedback: Math is more fun with 4-group Math!” Mathematics
          education has risen to the top of the national policy agenda as part
          of the need to improve the technical and scientific literacy of the
          American public. The new demands of international competition in the
          21st century require a workforce that is competent in, and comfortable
          with, mathematics.”<sup>1</sup> Some of the problems we have as a
          country are:
        </p>
        <ul>
          <li>
            Regardless of how much experience they have with common manipulative
            models, many children are not learning the mathematics they need or
            are expected to learn.<sup>2</sup>
          </li>
          <li>
            Some children do not automatically develop memory representations
            for basic arithmetic facts, even after years of using counting or
            other types of strategies.<sup>3</sup>
          </li>
          <li>
            Some children in grades 4 to 10 are still counting on their fingers,
            making marks to count on, or simply guessing at answers.<sup>4</sup>
          </li>
          <li>
            Common manipulative models used in math curriculums require children
            to count because their linear or random representations are not
            subitizable. If children miscount, they are wrong and have no way to
            check except to count again.
          </li>
        </ul>
        <p>
          4-group Math™ is an
          <a href="/media/Kuske_Math_Research_Findings_2009.pdf">
            &nbsp;evidence-based&nbsp;
          </a>
          program to develop sound number sense in all children. Using their
          innate subitizing ability and learning with products in our
          subitizable format, math success is now possible for all children.
        </p>

        <hr />

        <h2>Who Benefits?</h2>
        <p>
          4-groupMath™ benefits ALL children. It teaches to all learning styles
          so no child is left behind.
        </p>
        <p>
          “Learning Style is the way in which each learner begins to concentrate
          on, process, and retain new and difficult information.”<sup>5</sup>{' '}
          Learning Style is “a biologically and developmentally imposed set of
          personal characteristics that make the same teaching method effective
          for some children and ineffective for others.”<sup>6</sup> According
          to Dunn and Dunn, the four basic Learning Style modalities are
          Auditory, Visual, Tactual, and Kinesthetic.
        </p>
        <p>
          “65 percent of the population consists of visual/kinesthetic learners;
          therefore when teachers lecture, they are reaching less than half of
          the class. Children need learning strategies that accommodate their
          learning styles. Many of these learning strategies help not only the
          visual/kinesthetic learner but also make the classroom activities more
          engaging and therefore better learned by all.”<sup>7</sup>
        </p>

        <hr />

        <ol style={{ fontSize: '.75rem' }}>
          <li>
            Mathematics Learning in Early Childhood: Paths Toward Excellence and
            Equity; Christopher T. Cross, Taneisha A. Woods, and Heidi
            Schweingruber, Editors; Committee on Early Childhood Mathematics;
            National Research Council. Prepublication copy. ISBN: 0-309-12807-2,
            448 pages, 6 x 9, (2009), p. Summary-1.
          </li>
          <li>
            National Council of Teachers of Mathematics (2000). Principles and
            Standards for School Mathematics. Reston, VA: National Council of
            Teachers of Mathematics.
          </li>
          <li>
            Geary, D. C. (1994). Children’s Mathematical Development: Research
            and Practical Applications. Washington DC: American Psychological
            Association.
          </li>
          <li>
            Van de Walle, J. A. (1998). Elementary and Middle School
            Mathematics: Teaching Developmentally. New York: Longman.
          </li>
          <li>
            Dunn, Rita; http://www.geocities.com/~educationplace/Model.html
          </li>
          <li>
            Dunn, Beaudry, and Klavas, 1989;
            http://www.geocities.com/~educationplace/Model.html
          </li>
          <li>
            Patricia Vakos; http://www.geocities.com/~educationplace/Model.html
          </li>
        </ol>
      </Layout>
    )
  }
}

export const Query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    dots: file(relativePath: { eq: "science/4dots.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    parade: file(relativePath: { eq: "science/number-parade.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
